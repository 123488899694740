import React, { useState } from "react";
import {
  Button,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  useCreateInvestorMutation,
  useUpdateInvestorMutation,
  useLazyGetInvestorQuery,
} from "./api/entity-mapping.generated";

const EntityMappingScreen = () => {
  const [searchId, setSearchId] = useState<string>(""); // Input for search
  const [mappings, setMappings] = useState<any[]>([]); // Table data
  const [editRow, setEditRow] = useState<string | null>(null); // Row being edited
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false); // Create dialog state
  const [newRecord, setNewRecord] = useState<any>({
    customerId: "",
    externalId: "",
    internalId: "",
  });

  // Lazy Query and Mutations
  const [getInvestorQuery] = useLazyGetInvestorQuery(); // Lazy query
  const [createInvestorMutation] = useCreateInvestorMutation();
  const [updateInvestorMutation] = useUpdateInvestorMutation();

  // Handle "Search" Button
  const handleSearch = async () => {
    if (!searchId.trim()) {
      alert("Please enter a valid HW Investor ID to search.");
      return;
    }
    try {
      const response = await getInvestorQuery({ internalId: searchId });
      if (response.data?.getInvestor?.success) {
        const fetchedData = response.data.getInvestor.data || [];
        setMappings(fetchedData); // Update table with fetched data
        if (fetchedData.length === 0) alert("No results found.");
      } else {
        alert(response.data?.getInvestor?.errorMessage || "Error fetching data.");
        setMappings([]);
      }
    } catch (error) {
      console.error("Error fetching investor mappings:", error);
      alert("Error fetching investor mappings.");
    }
  };

  // Handle Editing a Row
  const handleEdit = (id: string) => {
    setEditRow(id);
  };

  // Handle Cancel Editing
  const handleCancelEdit = () => {
    setEditRow(null);
  };

  // Handle Saving Edited Row
  const handleSave = async (row: any) => {
    try {
      const response = await updateInvestorMutation({
        input: {
          id: row.id,
          externalId: row.externalId,
          internalId: row.internalId,
          customerId: row.customerId
        },
      });
      if (response.data?.updateInvestor?.success) {
        alert("Record updated successfully.");
        setEditRow(null);
      } else {
        alert(response.data?.updateInvestor?.errorMessage || "Update failed.");
      }
    } catch (error) {
      console.error("Error updating record:", error);
      alert("Failed to update record.");
    }
  };

  // Open "Create" Dialog
  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  // Close "Create" Dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setNewRecord({
      customerId: "",
      externalId: "",
      internalId: "",
    });
  };

  // Handle Creating a New Record
  const handleCreateSave = async () => {
    try {
      const response = await createInvestorMutation({
        input: newRecord,
      });
      if (response.data?.createInvestor?.success) {
        handleCloseCreateDialog();
        alert("New investor mapping created successfully.");
      } else {
        alert(response.data?.createInvestor?.errorMessage || "Creation failed.");
      }
    } catch (error) {
      console.error("Error creating new investor mapping:", error);
      alert("Failed to create new investor mapping.");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h1>Entity Mapping</h1>

      {/* Search and Create Buttons */}
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Search by HW Investor ID"
            variant="outlined"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ color: "white" }}
          >
            Search
          </Button>
        </div>
        <Button variant="contained" color="success" onClick={handleOpenCreateDialog}>
          Create Investor Mapping
        </Button>
      </div>

      {/* Results Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Customer ID</TableCell>
              <TableCell>External ID</TableCell>
              <TableCell>Internal ID</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Tenant ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.customerId}</TableCell>
                <TableCell>
                  {editRow === row.id ? (
                    <TextField
                      value={row.externalId}
                      onChange={(e) =>
                        setMappings((prev) =>
                          prev.map((r) =>
                            r.id === row.id ? { ...r, externalId: e.target.value } : r
                          )
                        )
                      }
                      size="small"
                      style={{ width: "120px" }}
                    />
                  ) : (
                    row.externalId
                  )}
                </TableCell>
                <TableCell>
                  {editRow === row.id ? (
                    <TextField
                      value={row.internalId}
                      onChange={(e) =>
                        setMappings((prev) =>
                          prev.map((r) =>
                            r.id === row.id ? { ...r, internalId: e.target.value } : r
                          )
                        )
                      }
                      size="small"
                      style={{ width: "120px" }}
                    />
                  ) : (
                    row.internalId
                  )}
                </TableCell>
                <TableCell>{row.provider}</TableCell>
                <TableCell>{row.tenantId}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  {editRow === row.id ? (
                    <>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleSave(row)}
                        >
                          Save
                        </Button>
                        <Button variant="outlined" onClick={handleCancelEdit}>
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEdit(row.id)}
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Investor Mapping Dialog */}
      <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
        <DialogTitle>Create Investor Mapping</DialogTitle>
        <DialogContent>
          {Object.keys(newRecord).map((key) => (
            <TextField
              key={key}
              label={key}
              variant="outlined"
              fullWidth
              margin="normal"
              value={newRecord[key]}
              onChange={(e) =>
                setNewRecord((prev: any) => ({
                  ...prev,
                  [key]: e.target.value,
                }))
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EntityMappingScreen;
