import { Box, Chip, CircularProgress, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

import Header from "../../../components/Header";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Link } from "react-router-dom";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useGetJobsMetaDataQuery, useGetJobsQuery } from "./api/jobs-management.generated";

const Jobs = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const { data: getJobs, isLoading, isError } = useGetJobsQuery();

  const jobs = getJobs?.getJobs?.data;

  if (isLoading || !jobs) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (isError || !jobs) {
    return (
      <Typography variant="h6" color="error" textAlign="center">
        Failed to load job data.
      </Typography>
    );
  }

  return (
    <Box m="20px">
      <Header title="Jobs" subtitle="Document generation, data syncing, etc." />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridAutoRows: "100px",
          gap: "20px",
        }}
      >
        {jobs.map((job: any) => (
          <Box
            key={job.name}
            sx={{
              backgroundColor: colors.background.paper,
              border: `1px solid ${colors.divider}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "1rem",
            }}
          >
            <Box sx={{ width: "250px" }}>
              <Typography variant="h4">{job.title}</Typography>
            </Box>
            <JobStats job = {job} />
            <Box>
              <IconButton
                component={Link}
                to={`/jobs/${job.name}`}
                state={{ title: job.title }}
              >
                <ArrowForwardOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.success.main }}
                />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};


const JobStats = (job: any) => {
  const { data: metaDataResponse, isLoading, isError } = useGetJobsMetaDataQuery({ jobName: job.job.name });
  const jobStats = metaDataResponse?.getJobsMetaData?.data?.stats;
  if (!jobStats)
    return;
  const renderChipIcon = (
    type:
      | "default"
      | "primary"
      | "secondary"
      | "error"
      | "info"
      | "success"
      | "warning"
  ) => {
    switch (type) {
      case "error":
        return <ErrorOutlineOutlinedIcon />;
      case "success":
        return <CheckCircleOutlineOutlinedIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  };

  if (isError || !job) {
    return (
      <Typography variant="h6" color="error" textAlign="center">
        Failed to load job meta data.
      </Typography>
    );
  }


  return <Box
    sx={{
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(8, 1fr)",
      gap: "20px",
    }}
  >
    {isLoading ? <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="secondary" />
    </Box> : jobStats.map((stat: any) => (
      <Box
        key={stat.name}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Chip
          variant="outlined"
          label={`${stat.value} ${stat.title}`}
          color={stat.type}
          icon={renderChipIcon(stat.type)}
        />
      </Box>))}
  </Box>;
};

export default Jobs;
