import React, { useCallback, useRef, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Chip,
  Alert,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from "@mui/lab";
import useChangeLog, { ChangelogCategory, ChangelogData, ChangelogDetails, ChangelogItem } from "./useChangelog";
import { debouncedOnChange } from "components/utils";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    height: "100vh"
  }
};

const Changelog = () => {
  const [searchJira, setSearchJira] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<ChangelogDetails>();

  const [page, setPage] = useState<number>(0);
  const observer = useRef<IntersectionObserver | null>(null);

  const { changelogData, hasMore, loading, errorMessage, setChangelogData } = useChangeLog(page, searchJira);

  const lastItem = useCallback((node: HTMLTableRowElement) => {
    if (loading) return;

    if (observer.current) {
      observer.current?.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(page => {
          return page + 1;
        });
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [loading, hasMore]);

  const handleJiraClick = (item: ChangelogItem, details: string[]) => {
    setModalData({ item, details });
    setOpenModal(true);
  };

  const renderChangelog = (data: ChangelogData) => {
    // Extract and sort dates in descending order
    const sortedDates = Object.keys(data).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    const result = [];

    for (let i = 0; i < sortedDates.length; i++) {
      const date = sortedDates[i];

      const categories: any = data[date];
      result.push(
        <TimelineItem key={date}>
          <TimelineOppositeContent>
            <Typography variant="h3">{date}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            {["Story", "Bug", "Task"].map((type) =>
              categories[type]
                ? Object.entries(categories[type] as ChangelogCategory).map(([key, { item, details }]) => {

                  if (i + 1 === sortedDates.length) {
                    return <Box ref={lastItem} key={key} mb={2}>
                      <Typography>
                        <Link href="#" onClick={() => handleJiraClick(item, details)}>
                          {item.jira}
                        </Link>{" "}
                        | {item.jiraType} | Assignee: {item.assignee} | Reporter: {item.reporter} | Environment: {item.environment}
                      </Typography>
                      <Typography>
                        <strong>Release Notes:</strong> {item.releaseNotes || "N/A"}
                      </Typography>
                      <Chip label={item.jiraType} color={item.jiraType === "Bug" ? "secondary" : "primary"} />
                    </Box>;

                  } else {

                    return <Box key={key} mb={2}>
                      <Typography>
                        <Link href="#" onClick={() => handleJiraClick(item, details)}>
                          {item.jira}
                        </Link>{" "}
                        | {item.jiraType} | Assignee: {item.assignee} | Reporter: {item.reporter} | Environment: {item.environment}
                      </Typography>
                      <Typography>
                        <strong>Release Notes:</strong> {item.releaseNotes || "N/A"}
                      </Typography>
                      <Chip label={item.jiraType} color={item.jiraType === "Bug" ? "secondary" : "primary"} />
                    </Box>;
                  }
                })
                : null

            )}
          </TimelineContent>
        </TimelineItem>
      );
    }
    return result;
  };

  const renderModalContent = (data: any) => {
    const { item, details } = data;
    return (
      <Box mb={2}>
        <Typography variant="h6" gutterBottom>
          <strong>{item.jira}</strong>
        </Typography>
        <Typography><strong>Assignee:</strong> {item.assignee}</Typography>
        <Typography><strong>Reporter:</strong> {item.reporter}</Typography>
        <Typography><strong>JIRA Type:</strong> {item.jiraType}</Typography>
        <Typography><strong>Environment:</strong> {item.environment}</Typography>
        <Typography><strong>Release Notes:</strong> {item.releaseNotes}</Typography>

        {details && details.length > 0 && (
          <Typography>
            <strong>Build Versions:</strong>
            <ul>
              {details.map((version: string, idx: number) => (
                <li key={idx}>{version}</li>
              ))}
            </ul>
          </Typography>
        )}

        {item.RefURL && (
          <Typography>
            <strong>Ref URL:</strong>{" "}
            <Link href={item.RefURL} target="_blank" rel="noopener">
              {item.RefURL}
            </Link>
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingLeft: 2, paddingRight: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>Changelog</Typography>
        <TextField
          label="Search by Jira Reference"
          onChange={(e) => debouncedOnChange(() => {
            setSearchJira(e.target.value);
            setPage(0);
            setChangelogData({});
          })}
          fullWidth
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>

      <Timeline>{renderChangelog(changelogData)}</Timeline>

      {!loading && !hasMore && (
        <div style={styles.container}>
          <Typography variant="h5">No More logs</Typography>
        </div>
      )}

      {loading && (
        <div style={styles.container}>
          <CircularProgress />
        </div>
      )}

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h3">Jira Details</Typography>
          <IconButton onClick={() => setOpenModal(false)}><CloseIcon /></IconButton>
        </DialogTitle>
        <DialogContent>
          {modalData && renderModalContent(modalData)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default Changelog;
