import React, { forwardRef } from "react";

import {
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";


import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Adviser } from "../../model";
import adviserStore from "../../api";
import { enqueueSnackbar } from "notistack";

interface TableRowProps {
    row: Adviser;
}

const AdviserUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const { disableMFAAdviser } = adviserStore;

  const handleDisableMFA = async () => {
    try {
      await disableMFAAdviser({ username: row.username, status: false });
      enqueueSnackbar(`MFA disabled successfully for ${row.username}`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error disabling MFA for ${row.username}`, { variant: "error" });

    }
  };

  const options: MenuItem[] = [
    {
      label: "Edit",
      onClick: () => navigate("/adviser-users/add-edit/" + row.id)
    },
    {
      label: "Disable MFA",
      onClick: handleDisableMFA,
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row?.first_name} {row?.last_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.created_at}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

export default AdviserUsersRow;
