import { useNavigate, useParams } from "react-router";
import { Box, FormControl, Grid, TextField, Link, Button, Typography, CircularProgress, styled, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import * as yup from "yup";
import { ReactNode, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";


import { enqueueSnackbar } from "notistack";
import loadingStore from "core/utils/loading";
import { observer } from "mobx-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { ErrorMessage as FormikErrorMessage, Formik } from "formik";
import adviserStore from "../api";

function AddEditAdviserUsers() {
  const navigate = useNavigate();
  const { getAdviser, createAdviser, updateAdviser, getRoles } = adviserStore;
  const { loading } = loadingStore;
  const [roles, setRoles] = useState<any[]>([]);


  const initialValues = {
    customer_id: "",
    employee_id: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    status: "PENDING",
    employee_to_be_created: false,
    io_app_user: false,
    role: { id: "", type: "" }
  } as any;

  const adviserUserScheme = yup.object({
    customer_id: yup.string().min(7).max(10).required("Customer id is required."),
    first_name: yup.string().max(50).required("First name is required."),
    last_name: yup.string().max(50).required("Last name is required."),
    username: yup.string().min(5).max(50).required("Username is required."),
    email: yup.string().email().required("Email is required."),
    status: yup.string().optional(),
    employee_to_be_created: yup.boolean().default(false),
    io_app_user: yup.boolean().default(false),
    employee_id: yup
      .string()
      .min(7)
      .max(10)
      .when("employee_to_be_created", ([employee_to_be_created], schema) => {
        return employee_to_be_created ? schema.notRequired() : schema.required("Employee id is required.");
      }),
    role: yup
      .object({
        id: yup.string().required("Role Id is required."),
        type: yup.string().length(3).required("Role Type is required."),
      })
      .when("employee_to_be_created", ([employee_to_be_created], schema) => {
        return employee_to_be_created ? schema.required("Role is required.") : schema.notRequired();
      })
  });

  const params = useParams();
  const isEdit = !!params?.id;
  const [dataValue, setDataValue] = useState<any>(initialValues);

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    if (isEdit) {
      const adviserUser = await getAdviser(params?.id);
      adviserUser.role = { id: "N/A", type: "N/A" };
      setDataValue(adviserUser);
    } else {
      setDataValue(initialValues);
    }
  };

  const fetchRoles = async (customerId: string) => {
    try {
      console.log(`[fetchRoles] Fetching roles for Customer ID: ${customerId}`);
      const response = await getRoles(customerId);

      if (response?.data?.rows) {
        setRoles(response.data.rows);
        console.log("[fetchRoles] API Response:", response.data.rows);
      }
    } catch (error) {
      console.error("[fetchRoles] Error fetching roles:", error);
    }
  };

  const handleSubmitForm = async (values: any) => {
    if (isEdit && params?.id) {
      const response = await updateAdviser(params.id, {
        customer_id: values.customer_id,
        employee_id: values.employee_id,
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username
      });
      if (response) {
        enqueueSnackbar("Adviser user has been successfully updated!!!", { variant: "success" });
        navigate("/adviser-users");
      }
    } else {
      const request = values.employee_to_be_created ?
        {
          customer_id: values.customer_id,
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          email: values.email,
          io_app_user: values.io_app_user,
          employee_to_be_created: true,
          role: {
            id: values.role.id,
            type: values.role.type,
          },
        } :
        {
          customer_id: values.customer_id,
          employee_id: values.employee_id,
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          email: values.email,
          io_app_user: values.io_app_user,
          employee_to_be_created: false
        };
      const response = await createAdviser(request);
      if (response) {
        enqueueSnackbar("Adviser user has been successfully created!!!", { variant: "success" });
        navigate("/adviser-users");
      }
    }
  };

  const formatError = (error: any): string | undefined => {
    if (typeof error === "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(", ");
    } else if (typeof error === "object") {
      console.log(error);
      if (error?.type) {
        return error.type;
      }
      return "An error occurred";
    }
    return undefined;
  };

  const ErrorMessage = styled(FormikErrorMessage)`
  margin-left: 1rem;
  color: #d32f2f;
  font-size: 0.7rem;`;

  const StyledPhoneInput = styled(PhoneInput)`
  .flag-dropdown {
    pointer-events: none;
  }`;

  interface CardProps {
    title: ReactNode;
    cardContent: ReactNode;
  }

  const CardComponent: React.FC<CardProps> = ({ title, cardContent }) => {
    return (
      <div style={{ border: "1px solid #ddd", borderRadius: 8, padding: "16px", width: "100%" }}>
        <header style={{ borderBottom: "1px solid #ddd", width: "100%" }}>{title}</header>
        <main style={{ marginTop: "20px" }}>{cardContent}</main>
      </div>
    );
  };

  return (
    <>
      <Box sx={{ py: 4, px: 4 }}>
        <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
          <Link underline="hover" onClick={() => navigate("/adviser-users")}>
            <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to Adviser Users
          </Link>
        </Box>
        <Formik
          onSubmit={handleSubmitForm}
          initialValues={dataValue}
          validationSchema={adviserUserScheme}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            handleBlur
          }) => {
            return <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <CardComponent
                  title={
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <h2>{isEdit ? "Edit Adviser User" : "Add Adviser User"}</h2>
                      {!isEdit && <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="io_app_user"
                              checked={values.io_app_user}
                              onChange={(val) => handleChange({ target: { name: "io_app_user", value: !!val.target.checked } })}
                              color="primary"
                            />
                          }
                          label="IO App User"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="employee_to_be_created"
                              checked={values.employee_to_be_created}
                              onChange={(val) => {
                                setFieldValue(
                                  "employee_to_be_created",
                                  !!val.target.checked
                                );
                                if (
                                  val.target.checked &&
                                  values.customer_id.length >= 7
                                ) {
                                  fetchRoles(values.customer_id);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label="Create Employee"
                        />
                      </div>}
                    </Box>
                  }
                  cardContent={
                    <Box sx={{ "& > *:not(:last-child)": { mb: 8 } }}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Customer Id"
                              name="customer_id"
                              onBlur={handleBlur}
                              value={values.customer_id}
                              onChange={(e) => {
                                const customerId = e.target.value.trim();
                                setFieldValue("customer_id", customerId);
                                if (values.employee_to_be_created && customerId.length >= 7) {
                                  fetchRoles(customerId);
                                }
                              }}
                              error={!!touched.customer_id && !!errors?.customer_id}
                              helperText={touched?.customer_id ? formatError(errors?.customer_id) : undefined}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        {!values.employee_to_be_created && <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Employee Id"
                              name="employee_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.employee_id}
                              error={!!touched.employee_id && !!errors?.employee_id}
                              helperText={touched?.employee_id ? formatError(errors?.employee_id) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        }

                        {values.employee_to_be_created && (
                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <Autocomplete
                              options={roles}
                              getOptionLabel={(option) => option.name || ""}
                              filterSelectedOptions
                              onChange={(_, selectedOption) => {
                                if (selectedOption) {
                                  setFieldValue("role", {
                                    id: selectedOption.id,
                                    type: selectedOption.id.substring(0, 3),
                                  });
                                }
                              }}
                              value={roles.find((role) => role.id === values.role.id) || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Role"
                                  error={!!touched.role && !!errors?.role}
                                  helperText={touched?.role ? formatError(errors?.role) : ""}
                                />
                              )}
                            />
                          </Grid>
                        )
                        }

                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="First Name"
                              name="first_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.first_name}
                              error={!!touched.first_name && !!errors?.first_name}
                              helperText={touched?.first_name ? formatError(errors?.first_name) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Last Name"
                              name="last_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.last_name}
                              error={!!touched.last_name && !!errors?.last_name}
                              helperText={touched?.last_name ? formatError(errors?.last_name) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Username"
                              name="username"
                              disabled={isEdit}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.username}
                              error={!!touched.username && !!errors?.username}
                              helperText={touched?.username ? formatError(errors?.username) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Email"
                              name="email"
                              disabled={isEdit}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              error={!!touched.email && !!errors?.email}
                              helperText={touched?.email ? formatError(errors?.email) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        {isEdit && <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                              fullWidth
                              labelId="status"
                              label="status"
                              placeholder="Status"
                              name="status"
                              readOnly={true}
                              value={values.status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!touched.status && !!errors?.status}>
                              <MenuItem value="PENDING">PENDING</MenuItem>
                              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>}
                      </Grid>
                    </Box>
                  }
                />
                <Box width="100%" display="flex" mt="10px" mb="10px" mr="10px">
                  <Button
                    style={{ marginTop: 10, width: "100px" }}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {isEdit ? (loading ? "UPDATING" : "UPDATE") : (loading ? "SAVING" : "SAVE")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </form>;
          }}
        </Formik>
      </Box>
    </>
  );
}

export default observer(AddEditAdviserUsers);
