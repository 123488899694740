import { useNavigate, useParams } from "react-router";
import { Box, FormControl, Grid, TextField, Link, Button, Typography, CircularProgress, styled, InputLabel, Select, MenuItem } from "@mui/material";
import { CardComponent } from "components/card/Card";
import * as yup from "yup";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import investorStore from "../api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import loadingStore from "core/utils/loading";
import { observer } from "mobx-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { ErrorMessage as FormikErrorMessage, Formik } from "formik";

function AddEditInvestorUsers() {
  const navigate = useNavigate();
  const { getInvestor, createInvestor, updateInvestor } = investorStore;
  const { loading } = loadingStore;

  const initialValues = {
    customer_id: "",
    investor_id: "",
    employee_id: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    status: "ACTIVE",
    phone_number: "",
    birthdate: ""
  } as any;

  const investorUserScheme = yup.object({
    customer_id: yup.string().min(7).max(10).required("Customer is is required."),
    investor_id: yup.string().min(11).max(11).required("Investor id is required."),
    employee_id: yup.string().min(7).max(11).required("Employee id is required."),
    first_name: yup.string().max(50).required("First name is required."),
    last_name: yup.string().max(50).required("Last name is required."),
    username: yup.string().min(5).max(50).required("Username is required."),
    email: yup.string().email().required("Email is required."),
    status: yup.string().required("Status is required."),
    phone_number: yup.string().required("Phone is required.")
      .matches(/^\d{10,15}$/, "Phone number is not valid"),
    birthdate: yup.string().required("Birthdate is required.")
  });

  const params = useParams();
  const isEdit = !!params?.id;
  const [dataValue, setDataValue] = useState<any>(initialValues);

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    if (isEdit) {
      const investorUser = await getInvestor(params?.id);
      investorUser.phone_number = investorUser.phone_number?.replace(/\+/g, "");
      setDataValue(investorUser);
    } else {
      setDataValue(initialValues);
    }
  };

  const handleSubmitForm = async (values: any) => {
    if (isEdit && params?.id) {
      const response = await updateInvestor(params.id, {
        customer_id: values.customer_id,
        investor_id: values.investor_id,
        employee_id: values.employee_id,
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        status: values.status,
        phone_number: `+${values.phone_number}`,
      });
      if (response && response.data) {
        enqueueSnackbar("Invesrtor user has been successfully updated!!!", { variant: "success" });
        navigate("/investor-users");
      }
    } else {
      const response = await createInvestor({
        customer_id: values.customer_id,
        investor_id: values.investor_id,
        employee_id: values.employee_id,
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        email: values.email,
        phone_number: `+${values.phone_number}`,
        birthdate: values.birthdate
      });
      if (response && response.data) {
        enqueueSnackbar("Invesrtor user has been successfully created!!!", { variant: "success" });
        navigate("/investor-users");
      }
    }
  };

  const formatError = (error: any): string | undefined => {
    if (typeof error === "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(", ");
    } else if (typeof error === "object") {
      return "An error occurred";
    }
    return undefined;
  };

  const ErrorMessage = styled(FormikErrorMessage)`
  margin-left: 1rem;
  color: #d32f2f;
  font-size: 0.7rem;`;

  const StyledPhoneInput = styled(PhoneInput)`
  .flag-dropdown {
    pointer-events: none;
  }`;

  return (
    <>
      <Box sx={{ py: 4, px: 4 }}>
        <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
          <Link underline="hover" onClick={() => navigate("/investor-users")}>
            <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to Investor Users
          </Link>
        </Box>
        <Formik
          onSubmit={handleSubmitForm}
          initialValues={dataValue}
          validationSchema={investorUserScheme}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            handleBlur
          }) => {
            return <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <CardComponent
                  title={isEdit ? "Edit Investor User" : "Add Investor User"}
                  cardContent={
                    <Box sx={{ "& > *:not(:last-child)": { mb: 8 } }}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Customer Id"
                              name="customer_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.customer_id}
                              error={!!touched.customer_id && !!errors?.customer_id}
                              helperText={touched?.customer_id ? formatError(errors?.customer_id) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Investor Id"
                              name="investor_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.investor_id}
                              error={!!touched.investor_id && !!errors?.investor_id}
                              helperText={touched?.investor_id ? formatError(errors?.investor_id) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Employee Id"
                              name="employee_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.employee_id}
                              error={!!touched.employee_id && !!errors?.employee_id}
                              helperText={touched?.employee_id ? formatError(errors?.employee_id) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="First Name"
                              name="first_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.first_name}
                              error={!!touched.first_name && !!errors?.first_name}
                              helperText={touched?.first_name ? formatError(errors?.first_name) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Last Name"
                              name="last_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.last_name}
                              error={!!touched.last_name && !!errors?.last_name}
                              helperText={touched?.last_name ? formatError(errors?.last_name) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Username"
                              name="username"
                              disabled={isEdit}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.username}
                              error={!!touched.username && !!errors?.username}
                              helperText={touched?.username ? formatError(errors?.username) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              type="text"
                              label="Email"
                              name="email"
                              disabled={isEdit}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              error={!!touched.email && !!errors?.email}
                              helperText={touched?.email ? formatError(errors?.email) : undefined}
                              sx={{
                                gridColumn: "span 4",
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        {isEdit && <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                              fullWidth
                              labelId="status"
                              label="status"
                              placeholder="Status"
                              name="status"
                              value={values.status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!touched.status && !!errors?.status}>
                              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>}
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <StyledPhoneInput
                              country={"gb"}
                              value={values.phone_number}
                              onBlur={handleBlur}
                              onChange={(phone) => handleChange({ target: { name: "phone_number", value: phone } })}
                              containerStyle={{ width: "100%" }}
                              inputStyle={{ width: "100%" }}
                              inputProps={{
                                shrink: true,
                                name: "phone_number",
                                required: true,
                                autoFocus: false,
                              }}
                              specialLabel="Phone"
                              countryCodeEditable={false}
                            />
                            <ErrorMessage
                              name="phone_number"
                              component="div"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                          <FormControl style={{ width: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="BirthDate"
                                name="birthdate"
                                disabled={isEdit}
                                onChange={(newValue) => {
                                  const formattedDate = newValue ? format(newValue, "yyyy-MM-dd") : "''";
                                  handleChange({ target: { name: "birthdate", value: formattedDate } });
                                }}
                                value={values.birthdate ? new Date(values.birthdate) : null}
                                maxDate={new Date()}
                                format="yyyy-MM-dd"
                                sx={{
                                  gridColumn: "span 4",
                                }}
                                slotProps={{
                                  textField: {
                                    InputProps: { readOnly: true, disabled: true },
                                  },
                                }}
                              />
                              <ErrorMessage
                                name="birthdate"
                                component="div"
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
                <Box width="100%" display="flex" mt="10px" mb="10px" mr="10px">
                  <Button
                    style={{ marginTop: 10, width: "100px" }}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {isEdit ? (loading ? "UPDATING" : "UPDATE") : (loading ? "SAVING" : "SAVE")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </form>;
          }}
        </Formik>
      </Box>
    </>
  );
}

export default observer(AddEditInvestorUsers);
